import React from 'react'
import './App.css'
import { theme, ThemeProvider } from '@papertrail/styleguide'
import { BrowserRouter } from 'react-router-dom'
import UniversalSearch from './components/UniversalSearch'

export default function Root() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <UniversalSearch />
      </BrowserRouter>
    </ThemeProvider>
  )
}
